@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;400;600&display=swap");

/* colors */
:root {
  --orange: #ff6a00;
  --lightorange: rgba(255, 106, 0, 0.7);
  --black: #231f20;
  --grey: #bbbbbb;
  --lightgrey: #eaeaea;
  --green: #376058;
  --white: #fffdfa;
}

body {
  margin: 0;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--white);
  color: #121315;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

p {
  font-size: calc(12px + 0.3vw);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: calc(14px + 0.3vw);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {
  box-sizing: border-box;
  scroll-behavior: smooth;
  overflow-x: hidden;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

/* toastify */

.Toastify__toast-body > div:last-child {
  font-size: .9rem;
}